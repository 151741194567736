/** @jsx jsx */
import { jsx, useThemeUI } from "theme-ui";
import { Fragment, useEffect } from "react";
import { Heading, Flex, Box, Text, Link } from "rebass";
import { StaticImage } from "gatsby-plugin-image";
import ProjectTitleListing from "../../../components/ProjectTitleListing";
import Hero from "../../../components/Hero";
import LPcampaignVideo from "../../../img/lapierre/LP-OCampaign-Hero.mp4";
import LPImageGrid from "../../../img/lapierre/LP-imageGrid.mp4";
import LPcampaignVideoNico1 from "../../../img/lapierre/LAPIERRE_MAIN_16x9_1_Nico_Vouilloz.mp4";
import LPcampaignVideoNico2 from "../../../img/lapierre/LAPIERRE_MAIN_16x9_Nico_Vouilloz.mp4";
import LPProductLineFull from "../../../img/lapierre/OProductLineFull.mp4";
import ProjectFadeInTitle from "../../../components/ProjectFadeInTitle";
import BackToTop from "../../../components/BackToTop";
import CompanyProjects from "../../../components/CompanyProjects";
import BookScene from "../../../components/BookScene";

import Layout from "../../../layouts/";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import ThemeColorSetter from "../../../components/ThemeColorSetter";

interface IProps {
  year: string;
  title?: string | undefined;
  subtitle: string | undefined;
  shortDesc: string | undefined;
  img: string;
}

function LPProject(props: IProps) {
  const context = useThemeUI();
  const { theme } = context;
  const projectBgColor = theme?.colors?.accellProjectBgLP as string;
  const projectTextColor = theme?.colors?.accellProjectTextLP as string;
  const themeColor = theme?.colors?.accellProjectBgLP as string;
  const themeColorBg = theme?.colors?.background as string;

  useEffect(() => {
    const handleScroll = () => {
      const heroSection = document.getElementById("hero-section");
      if (heroSection) {
        const rect = heroSection.getBoundingClientRect();
        if (rect.bottom > 0) {
          document.body.classList.add("hero-visible");
          document.body.classList.remove("hero-hidden");
        } else {
          document.body.classList.add("hero-hidden");
          document.body.classList.remove("hero-visible");
        }
      } else {
        document.body.classList.add("hero-hidden");
        document.body.classList.remove("hero-visible");
      }
    };

    const heroSection = document.getElementById("hero-section");
    if (heroSection) {
      window.addEventListener("scroll", handleScroll);
      handleScroll(); // Initial check
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    } else {
      document.body.classList.add("hero-hidden");
      document.body.classList.remove("hero-visible");
    }
  }, []);

  return (
    <Layout>
      <ThemeColorSetter color={themeColor} colorBg={themeColorBg} />
      <main>
        <Header bgColor={projectBgColor} textColor={projectTextColor} />
        <Hero
          projectType={"Case study"}
          productType={"eCommerce, Website, Visual Design"}
          title={"L'Équipe"}
          titleRow={"Lapierre"}
          titleClassName={"uppercase"}
          subtitle={"Performance as a mindset"}
          projectBg={theme?.colors?.accellProjectBgLP as string}
          projectText={theme?.colors?.accellProjectTextLP as string}
          shortDesc={
            <Fragment>
              One name, 70 years of innovation, shared passion. With a French
              touch.
            </Fragment>
          }
          year={"2020"}
          company={"ACCELL"}
          role={
            <Fragment>
              Strategy Design Systems <br />
              Design + Execution <br /> UI Engineering <br />
              Assets & Content Management
            </Fragment>
          }
          roleDesc={
            <Fragment>
              I was part of the established design team and responsible for the
              design system and visual experience strategy of the new global
              Lapierre website. I lead the UI and art direction work, producing
              all major deliverables and presenting these to stakeholders.
              <br />
              I worked alongside branding managers, a graphic designer, content
              editors, SEO specialist, developers, a UX Lead and a product
              owner.
              <br />
              Daily operations included working agile in 2 weeks sprints.{" "}
            </Fragment>
          }
          disciplines={
            <Fragment>
              Design &mdash; Branding &mdash; Content &mdash; Development
              &mdash; SEO
            </Fragment>
          }
          challenge={
            <Fragment>
              Our team was under pressure from both a deadline and a pandemic.
              Communication proved rather difficult at times due to external
              personal factors and distancing. However{" "}
              <strong>team proved resilience and professionalism</strong>. On
              top of this our design system and our platform needed to adjust to
              new requirements from stakeholders, which wanted a strong visual
              presence. <br />
              Existing content was in need of a management system. New assets
              needed to be created.
            </Fragment>
          }
          component={
            <BookScene
              coverColor={"#f0c844"}
              textColor={"silver"}
              urlTexture={"/models/textures/lp-book-cover.jpg"}
            />
          }
        />
        {/* <BookScene coverColor={theme?.colors?.coverBook as string } textColor={theme?.colors?.bigTitle as string } urlTexture={'/models/textures/lp-book-cover.jpg'} /> */}
        {/* <BoxScene coverColor={"#DB0200" } textColor={theme?.colors?.bigTitle as string } urlTexture={'/models/textures/p_box_set_01.003_1.jpg'} /> */}

        {/* User story */}
        <Flex
          {...props}
          flexWrap="wrap"
          justifyContent="center"
          variant="container"
          id="project-content"
        >
          <Box variant="containerTxt">
            <Heading as="h6" variant="catHeading">
              User story
            </Heading>
            <Text as="p" pb={[3, 4]} mb={[4, 5]} variant="paragraphXL">
              <strong>As an</strong> active biker
              <br />
              <strong>I want to</strong> benefit of the latest and greatest
              technology
              <br />
              <strong>So that</strong> I can feel empowered, confident and
              self-aware.
            </Text>
            <Heading as="h2" variant="bigTitleXS">
              Persona, Goals <br />
              And Emotional Design
            </Heading>
            <Text as="p">
              When I'm being asked to "make it pretty", I fall back to
              "emotional design". According to{" "}
              <strong>Don Norman’s book &mdash; "Emotional Design"</strong>,
              it’s important to translate end user goals into the product’s
              experience. We need to consider the user’s motivations:
            </Text>
            <ul>
              <li>
                <strong>How</strong> a user wants to <strong>feel</strong>
              </li>
              <li>
                <strong>What</strong> a user wants to <strong>do</strong>
              </li>
              <li>
                <strong>Who</strong> a user wants to <strong>be</strong>
              </li>
            </ul>
            <Box variant="wrapperImg">
              <StaticImage
                src="../../../img/ecommerce/sport-persona.jpg"
                alt="Sports persona"
              />
            </Box>
          </Box>
        </Flex>
        <Flex
          {...props}
          flexWrap="wrap"
          justifyContent="center"
          variant="container"
        >
          <Box width={[1 / 1]} variant="containerBigTitle">
            {/* Design System */}
            <ProjectFadeInTitle
              word1={"Design"}
              word2={"System"}
              className={"uppercase"}
              word3={"at work"}
              word3ClassName={"outline uppercase"}
            />
          </Box>
          <Box variant="containerTxt">
            <Text as="p" mb={[5, 6]}>
              The aim of the Design System is to ensure we all{" "}
              <strong>
                share the same point of view on the position of Lapierre in the
                world
              </strong>
              , so that we can continue to build our global brand in a uniform
              and consistent way.
              <br />
              Based on OOUX(Object Oriented UX) framework. Add Gatsby, kept
              together by Storyblok, mixed with Shopware.
            </Text>
            <Heading as="h2" variant="bigTitleXS" mt={[5, 6]}>
              Brand Driven <br />
              Core Design Principles
            </Heading>
            <Text as="p">
              Brand benefits were at the core of design decisions:
            </Text>
            <ul>
              <li>
                <strong>Target audience</strong> of sporty and active leisure
                bikers{" "}
              </li>
              <li>
                <strong>Functional design</strong> to incorporate quality and
                innovation
              </li>
              <li>
                <strong>Emotional design</strong> to empower, feel confident and
                self-aware
              </li>
            </ul>
            <Heading as="h2" variant="bigTitleXS" mt={[5, 6]}>
              From Brand Book to <br />
              Online Presence (Web Design)
            </Heading>
            <Text as="p">
              To understand Lapierre’s spirit we must first acknowledge why the
              brand exists, what do they do and what drives them.
            </Text>
            <ul>
              <li>
                <strong>Brand story, archetype and foundations</strong> &mdash;
                for understanding the brand essence and the target audience
              </li>
              <li>
                <strong>Visual Guidelines</strong> &mdash; for achieving the
                best visual content when creating and using product imagery,
                beauty shots or lifestyle photography
              </li>
              <li>
                <strong>Visual Language</strong> &mdash; for aligning visual
                constrains within cross functional teams:
                <br />
                Color / Space, Grid and Layout / Fluid Typography / Iconography
                / Interaction / Motion / Imagery
              </li>
            </ul>
            <Heading as="h2" variant="bigTitleXS" mt={[5, 6]}>
              Visual Design <br />
              in Balance with UX and Content
            </Heading>
            <Text as="p">
              We took the challenge to implement a dark theme to suit the
              brand’s art direction of
              <strong> Suspence</strong> &mdash; <strong>Subtetly</strong>{" "}
              &mdash; <strong>Balance.</strong>
              <br />
              "Lapierre’s elegance lies in tension and suspense. By not
              revealing the gear in its entirety. By leaving our audience to
              wonder."
            </Text>
            <Text as="p" mb={[4]}>
              According to the branding book: &quot;We achieve that by playing
              with lights and shadows, underlining the gear or emotion we want
              to highlight, and leaving the rest in the dark. Visuals have a
              unique signature, half-way between premium shots and reverie
              photography.&quot;
            </Text>
            <Link
              variant="aLink"
              href="https://zeroheight.com/6ebd1dfb8/p/29ff3a-imagery/b/4409a1"
              target="_blank"
              rel="noopener norefferer"
              mb={[1, 2]}
            >
              View Design System *
            </Link>
            <br />
            <Text as="span" fontSize={0}>
              * <strong>NOTE:</strong> Only the chosen ones may see it, so be
              prepared for a password.
            </Text>
          </Box>
          <Box width={[1 / 1]}>
            <Flex flexWrap="wrap" justifyContent="center" alignItems="flex-end">
              <Box width={[1 / 1]}>
                <Heading as="h3" variant="catHeading">
                  UX &amp; Visual pleasantries
                </Heading>
                <Text as="span" pb={[5]} maxWidth={["48rem"]}>
                  Users want to see a beautiful bike where technology prevails.
                  Match the filename with an SEO keyword and the right copy
                  description and you design for both robots and humans.{" "}
                </Text>
              </Box>
              <Box width={[1 / 1, 4 / 12]} pr={[0, 4]}>
                <StaticImage
                  src="../../../img/lapierre/LP-aircode-wheel-disc.jpg"
                  alt="Lapierre - Subtetly"
                />
              </Box>
              <Box width={[1 / 1, 8 / 12]}>
                <StaticImage
                  src="../../../img/lapierre/LP-aircode-handle-bar.jpg"
                  alt="Lapierre - Subtetly"
                />
              </Box>
            </Flex>
          </Box>
        </Flex>

        {/* Colors and accessibility */}

        <Flex flexWrap="wrap" variant="container" justifyContent="center">
          <Box width={[1 / 1]} variant="containerTxt">
            <Heading as="h3" variant="catHeading">
              Brand, Neutrals, Semantic
            </Heading>
            <Heading as="h2" variant="bigTitleXS">
              Colors and <br />
              Accessibility
            </Heading>
            <Text as="p">
              To structure an easy maintenance strategy within the multi-brand
              headless platform the colors were split as follows:
            </Text>
            <ul>
              <li>
                <strong>Brand</strong>
              </li>
              <li>
                <strong>Global</strong>
                <ul>
                  <li>
                    <strong>Neutrals</strong> &mdash; they can work alongside
                    any brand color and don’t draw a user’s attention away from
                    the main focus on a color manipulation task or workflow.{" "}
                  </li>
                  <li>
                    <strong>Semantic</strong> &mdash; they have assigned
                    meanings used sparingly and intentionally to reinforce
                    hierarchies and to create clear modes of communication.
                  </li>
                </ul>
              </li>
            </ul>
            <Text as="p">
              This allows the brands to customize their own colors without the
              fuss of dealing with selecting functional colors. Neutrals and
              semantic palettes have been carefully chosen and tested to ensure
              the best possible contrast (AA/AAA compliant). They also follow
              design patterns to set expectations of meaning for users.
            </Text>
          </Box>
          <Box width={[1 / 1]} textAlign="center">
            <StaticImage
              src="../../../img/lapierre/LP-neutrals-contrast-ratios.jpg"
              alt="Design System - Colors Contrast Ratios"
            />
          </Box>
        </Flex>

        {/* Full-in Brand Experience */}
        <Flex flexWrap="wrap" justifyContent="center" variant="container">
          <Box width={[1 / 1]} variant="containerBigTitle">
            <ProjectFadeInTitle
              word1={"Full-On"}
              word2={"Brand"}
              word2ClassName={"outline"}
              word3={"experience"}
              className={"uppercase"}
            />
          </Box>
          <Box width={[1 / 1]} variant="wrapperVideo" mt={[5, 6]}>
            <video autoPlay muted loop playsInline>
              <source src={LPcampaignVideo} type="video/mp4" />
            </video>
          </Box>
          <Box width={[1 / 1]} variant="containerTxt" mt={[3, 4, 5]}>
            <Heading as="h3" variant="catHeading" mt={[3, 4, 5]}>
              It's not about a trend
            </Heading>
            <Heading as="h2" variant="bigTitleXS">
              Light vs. Dark
            </Heading>
            <Text as="p" my={[2, 4]}>
              <strong>Dark mode</strong> was a requirement from the Branding
              team, as a stakeholder. The UI needed to be dark when showing{" "}
              <strong>beauty shots</strong>
              <sup>(1)</sup> to{" "}
              <strong>enhance the elegance of the bike design</strong>
              <sup>(2)</sup>.
            </Text>
            <ul>
              <li>
                <strong>
                  Beauty shots <sup>(1)</sup>{" "}
                </strong>{" "}
                offer an in depth detail shot of a product and can be very
                effective at highlighting a product's design features and/or
                technology. They are different from a product detail shot as the
                art direction approach is to quantify as much as possible the
                design. Beauty shots can effectively be used instead of product
                detail shots.{" "}
              </li>
              <li>
                <strong>
                  Reasoning <sup>(2)</sup>{" "}
                </strong>{" "}
                — Being photographed against a dark background would highlight
                the frame shape and its color, thus a light UI background would
                take away the focus. Achieving the best contrast was key.{" "}
              </li>
            </ul>
            <Text as="p" mb={[2, 4]}>
              In opposition to orientation pages, the functional and decision
              making sections of the website were kept on a{" "}
              <strong>light mode</strong>, to ensure that users’s experience was
              not altered. Such is the case for product listing, checkout and
              articles.
            </Text>
            <Text as="p">
              In achieving technical depth in visual design the following were
              carefully considered and touched upon:
            </Text>
            <ul>
              <li>
                <strong>Brightness</strong> &mdash; to distinguish details (text
                and imagery work seamlessly).
              </li>
              <li>
                <strong>Contrast</strong> &mdash; to ensure legibility and
                focus.
              </li>
              <li>
                <strong>Layout density</strong> &mdash; to display relevant
                product information based on Marketing research consolidated by
                UX research and validation.
              </li>
            </ul>
            <Flex flexWrap="wrap" my={[3, 4, 5]}>
              <Box width={[1 / 1, 1 / 2]}>
                <StaticImage
                  src="../../../img/lapierre/LP-product-dark-theme.jpg"
                  alt="Lapierre - OCampaignListBanner"
                />
              </Box>
              <Box width={[1 / 1, 1 / 2]} variant="wrapperImg">
                <StaticImage
                  src="../../../img/lapierre/LP-product-light-theme.jpg"
                  alt="Lapierre - OCampaignListBanner"
                />
              </Box>
            </Flex>
          </Box>
        </Flex>

        {/* Campaign The Visual Object */}
        <Flex flexWrap="wrap" justifyContent="center" variant="container">
          <Box width={[1 / 1]} variant="containerBigTitle">
            <ProjectFadeInTitle
              word1={"Campaign"}
              word3={"the visual"}
              word3ClassName={"outline"}
              word4={"object"}
              className={"uppercase"}
            />
          </Box>
          <Box variant="containerTxt" textAlign="left" mt={[4, 5, 6]}>
            <Heading as="h6" variant="catHeading">
              The Campaign Object
            </Heading>
            <Text as="p" variant="paragraphXL">
              <strong>A campaign</strong> is a series of activities around
              informing, reminding or persuading its target audience about its
              product or service, across various mediums.
            </Text>
          </Box>
          <Box width={[1 / 1]}>
            <Flex justifyContent="center" flexWrap="wrap">
              <Box width={[1 / 1, 1 / 1, 1 / 1, 1 / 2]} pr={[0, 2, 3]}>
                <Box variant="wrapperVideo">
                  <video autoPlay muted loop playsInline>
                    <source src={LPcampaignVideoNico1} type="video/mp4" />
                  </video>
                </Box>
              </Box>
              <Box
                width={[1 / 1, 1 / 1, 1 / 1, 1 / 2]}
                pl={[0, 2, 3]}
                pt={[4, 6]}
              >
                <Text
                  as="p"
                  py={[4, 6]}
                  sx={{ maxWidth: "56.8rem", margin: "0 auto" }}
                >
                  Content blocks are conected with other defined objects making
                  possible various combinations to help quickly, easily and with
                  speed build an online campaign.
                </Text>
                <Box variant="wrapperVideo">
                  <video autoPlay muted loop playsInline>
                    <source src={LPcampaignVideoNico2} type="video/mp4" />
                  </video>
                </Box>
              </Box>
            </Flex>
          </Box>
        </Flex>

        <Flex
          flexWrap="wrap"
          justifyContent="center"
          alignItems="flex-start"
          variant="container"
        >
          <Box width={[1 / 1, 1 / 2, 1 / 2, 1 / 4]} p={[2, 4, 5, 5, 6]}>
            <StaticImage
              src="../../../img/lapierre/LP-OCampaignFull-Banner.png"
              alt="Lapierre - OCampaignListBanner"
            />
            <Text as="span" variant="caption">
              <strong>Campaign Banner</strong> - the image or video should is
              different from the one in used on the homepage to avoid being
              flagged as duplicate content.
            </Text>
          </Box>

          <Box width={[1 / 1, 1 / 2, 1 / 2, 1 / 4]} p={[2, 4, 5, 5, 6]}>
            <StaticImage
              src="../../../img/lapierre/LP-OCampaignFull-USPs.png"
              alt="Lapierre - OCampaignListBanner"
            />
            <Text as="span" variant="caption">
              <strong>USPs</strong> - uses the same content block with different
              content managed from the CMS.
            </Text>
          </Box>

          <Box width={[1 / 1, 1 / 2, 1 / 2, 1 / 4]} p={[2, 4, 5, 5, 6]}>
            <StaticImage
              src="../../../img/lapierre/LP-OProductLine.png"
              alt="Lapierre - OCampaignListBanner"
            />
            <Text as="span" variant="caption">
              <strong>Product line object</strong> shows a preview of the entire
              range of products, including access to the product detail.
            </Text>
          </Box>

          <Box width={[1 / 1, 1 / 2, 1 / 2, 1 / 4]} p={[2, 4, 5, 5, 6]}>
            <StaticImage
              src="../../../img/lapierre/LP-OverlayMenu-OCampaignListXs.png"
              alt="Lapierre - OCampaignListBanner"
            />
            <Text as="span" variant="caption">
              <strong>Overlay Menu</strong> - shows active campaign. Please note
              that content managers are able to do so via CMS from only place.
              Multiple active campaigns are also possible.
            </Text>
          </Box>
        </Flex>
        <Flex
          flexWrap="wrap"
          justifyContent="center"
          variant="container"
          mb={[5, 6, 7]}
        >
          <Box width={[1 / 1]} variant="containerTxt" pr={[0, 3, 5]}>
            <Heading as="h3" variant="catHeading">
              By The rules
            </Heading>
            <Heading as="h2" variant="bigTitleXS">
              Image Product Gallery
            </Heading>
            <Text as="p">
              Image grid is, as its name suggests, a solution to display all
              product images in an engaging way. It was designed to work with{" "}
              <strong>aspect ratio</strong> as follows:
            </Text>
            <ul>
              <li>
                <strong>4 : 3</strong> for landscape
              </li>
              <li>
                <strong>2 : 4</strong> for portrait
              </li>
            </ul>
            <Text as="p">
              <strong>Note</strong> &mdash; Aspect ratios ensure that the grid
              works properly and everything is neat. Even though it works
              without uploading the proper images it is recommended that content
              editors do so.
            </Text>
          </Box>
          <Box width={[1 / 1]} mb={[3, 4]} textAlign="center">
            <StaticImage
              src="../../../img/lapierre/LP-image-grid-ds.jpg"
              alt="Lapierre - Image Grid Design System Rules"
            />
          </Box>
          <Box width={[1 / 1]}>
            <Box variant="wrapperVideo">
              <video autoPlay muted loop playsInline>
                <source src={LPImageGrid} type="video/mp4" />
              </video>
            </Box>
          </Box>
        </Flex>
        <Flex
          flexWrap="wrap"
          justifyContent="center"
          alignItems="flex-end"
          variant="container"
        >
          <Box width={[1 / 1, 1 / 2, 1 / 3]} p={[2, 4, 5, 5, 6]}>
            <StaticImage
              src="../../../img/lapierre/LP-ProductFull-Hero.png"
              alt="Lapierre - OCampaignFull Product Hero"
            />
            <Text as="span" variant="caption" textAlign="center" mx={["auto"]}>
              <strong>Product hero</strong> &mdash; enhances the product and
              pushes the brand.
            </Text>
          </Box>

          <Box width={[1 / 1, 1 / 2, 1 / 3]} p={[2, 4, 5, 5, 6]}>
            <StaticImage
              src="../../../img/lapierre/LP-OProductGallery.png"
              alt="Lapierre - OCampaignFull Product Gallery"
            />
            <Text as="span" variant="caption" textAlign="center" mx={["auto"]}>
              <strong>Product gallery</strong> &mdash; product images are taken
              to the next level.
            </Text>
          </Box>
          <Box width={[1 / 1, 1 / 2, 1 / 3]} p={[2, 4, 5, 5, 6]}>
            <StaticImage
              src="../../../img/lapierre/LP-OProductFull-size-and-geometry.png"
              alt="Lapierre - OCampaignFull Product Hero"
            />
            <Text as="span" variant="caption" textAlign="center" mx={["auto"]}>
              <strong>Size & geometry section</strong> &mdash; industry standard
              plussed by interaction.
            </Text>
          </Box>
        </Flex>

        {/* Product Series */}
        <Flex flexWrap="wrap" justifyContent="center" variant="container">
          <Box width={[1 / 1]} variant="containerBigTitle">
            <ProjectFadeInTitle
              word1={"The"}
              word2={"Product"}
              word2ClassName={"outline"}
              word3={"Line"}
              word3ClassName={"outline"}
              className={"uppercase"}
              word4={"Series"}
            />
          </Box>
          <Box width={[1 / 1]} variant="containerTxt">
            <Text as="p" mb={[2, 4]}>
              <strong>OProductLine</strong> &mdash; A product line is a group of
              connected products marketed under a single brand name by the same
              company.
            </Text>
          </Box>
          <Box width={[1 / 1]} variant="wrapperVideo">
            <video autoPlay muted loop playsInline>
              <source src={LPProductLineFull} type="video/mp4" />
            </video>
          </Box>
        </Flex>
        {/* Product Line Object */}
        <Flex flexWrap="wrap" justifyContent="center" variant="container">
          <Box width={[1 / 1, 1 / 2, 1 / 3]} p={[2, 4, 5, 5, 6]}>
            <StaticImage
              src="../../../img/lapierre/LP-OProductLineFull-Hero.png"
              alt="Lapierre - OCampaignFull Product Hero"
            />
            <Text as="span" variant="caption" mx={["auto"]} textAlign="center">
              <strong>Aircode DRS</strong> &mdash; Product line Hero
            </Text>
          </Box>
          <Box width={[1 / 1, 1 / 2, 1 / 3]} p={[2, 4, 5, 5, 6]}>
            <StaticImage
              src="../../../img/lapierre/LP-OProductLineFull-content-blocks.png"
              alt="Lapierre - OCampaignFull Product Hero"
            />
            <Text as="span" variant="caption" mx={["auto"]}>
              <strong>Size & geometry section</strong> &mdash; industry standard
              plussed by interaction.
            </Text>
          </Box>
          <Box width={[1 / 1, 1 / 2, 2 / 5]} p={[2, 4, 5, 5, 6]}>
            <StaticImage
              src="../../../img/lapierre/LP-2-devices-front-left.png"
              alt="Lapierre - OProductLineXL"
            />
            <Text as="span" variant="caption">
              <strong>OProductLineXL</strong> &mdash; Small screens.
            </Text>
          </Box>
          <Box width={[1 / 1, 1 / 2, 3 / 5]} p={[2, 4, 5, 6]}>
            <StaticImage
              src="../../../img/lapierre/LP-OproductLineXL-4-bikes-carousel.jpg"
              alt="Lapierre - OProductLineXL Product Hero"
            />
            <Text as="span" variant="caption">
              <strong>ProductLineXL</strong> &mdash; Large screens.
            </Text>
          </Box>
        </Flex>

        {/* Basket */}

        <Flex
          flexWrap="wrap"
          justifyContent="center"
          variant="container"
          alignItems="flex-end"
        >
          <Box width={[1 / 1]} variant="containerBigTitle">
            <ProjectFadeInTitle
              word1={"It's"}
              word2={"in"}
              word2ClassName={"outline"}
              word3={"the"}
              word4={"Basket"}
              className={"uppercase"}
            />
          </Box>
          <Box width={[1 / 1, 1 / 2, 3 / 5]} p={[2, 4, 5, 6]} mt={[4]}>
            <StaticImage
              src="../../../img/lapierre/LP-OBasketFull-large-screen.jpg"
              alt="Lapierre - Basket - Large screens"
            />
            <Text as="span" variant="caption">
              <strong>Basket</strong> &mdash; Large screens.
            </Text>
          </Box>
          <Box width={[1 / 1, 1 / 2, 2 / 5]} p={[2, 4, 5, 6]}>
            <StaticImage
              src="../../../img/lapierre/LP-OBasketFull-2-devices-front-right.png"
              alt="Lapierre - Basket - Small screens"
            />
            <Text as="span" variant="caption" textAlign="center" mx={["auto"]}>
              <strong>Basket</strong> &mdash; Small screens.
            </Text>
          </Box>
        </Flex>

        {/* Deliverables and personal note */}
        <Flex flexWrap="wrap" justifyContent="center" variant="container">
          <Box variant="containerTxt">
            <Heading as="h3" variant="catHeading">
              The End
            </Heading>
            <Heading as="h3" variant="bigTitleXS">
              Deliverables
            </Heading>
            <ul>
              <li>UI Library in Sketch</li>
              <li>Documentation</li>
              <li>Code (for UI Components)</li>
              <li>Assets</li>
              <li>Design Tokens</li>
              <li>Prototyping</li>
              <li>CMS schema setup (alongside Content and UX) </li>
              <li>
                Zeroheight for&nbsp;
                <Link
                  variant="aLink"
                  href="https://zeroheight.com/6ebd1dfb8/p/9979d8-lapierre"
                  target="_blank"
                  rel="noopener norefferer"
                  mb={"0px"}
                >
                  Lapierre
                </Link>
              </li>
            </ul>
            <Heading as="h3" variant="catHeading" mt={[3, 4, 5]}>
              Personal Note
            </Heading>
            <Heading as="h2" variant="bigTitleXS">
              The Best Rides <br />
              are Bumpy
            </Heading>
            <Text as="p">
              Lapierre offered me the chance to blend many perspectives within a
              single user experience. In hindsight I should have been more vocal
              when designing with content that we didn't have. I should have
              promoted more the importance of Visual and Emotional Design within
              the customer experience. Usability was put at risk for both
              customers and teams involved.
            </Text>
            <Heading as="h3" variant="catHeading" mt={[3, 4, 5]}>
              Out there
            </Heading>
            <Link
              variant="buttons.primary"
              href="https://www.lapierrebikes.com"
              target="_blank"
              rel="noopener norefferer"
              py={[3]}
              my={[3, 4]}
              mr={[3]}
            >
              View Lapierre.com
            </Link>

            <Link
              variant="buttons.primary"
              href="https://www.lapierrebikes.com"
              target="_blank"
              rel="noopener norefferer"
              py={[3]}
              my={[3, 4]}
            >
              View Design System
            </Link>
          </Box>
        </Flex>
        <CompanyProjects title="ACCELL" />
        <ProjectTitleListing />
        <BackToTop />
        <Footer />
      </main>
    </Layout>
  );
}
export default LPProject;
